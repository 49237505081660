import React, { useEffect, useState } from "react";
import "./Navbar.scss";
import { Link } from "gatsby";
// @ts-ignore
import headerIcon from "../../assets/img/old/header.svg";
// @ts-ignore
import headerMiniIcon from "../../assets/img/coachello-mini.svg";
import { useIsTop } from "../../hooks/useNavStatus";
import useLocale from "../../hooks/useLocale";
// @ts-ignore
import individualCoachingIcon from "../../assets/img/icons/individual-coaching.svg";
// @ts-ignore
import groupWorkshopsIcon from "../../assets/img/icons/group-workshops.svg";
// @ts-ignore
import internalCoachingIcon from "../../assets/img/icons/internal-coaching.svg";
// @ts-ignore
import executiveCoachingIcon from "../../assets/img/icons/executive-coaching.svg";
// @ts-ignore
import aiSolutionsIcon from "../../assets/img/icons/aisol.svg";

import Img from "../../components/Img";

const Navbar = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isSubNavExpanded, setIsSubNavExpanded] = useState(false);
  const [prefix, setPrefix] = useState("");
  const [isSubCaseExpanded, setIsSubCaseExpanded] = useState(false);
  const isTop = useIsTop();
  const locale = useLocale();
  useEffect(() => {
    setPrefix(locale == "fr" ? "/fr" : "");
  }, [locale]);
  return (
    <header
      id="#navbar"
      className={"Navbar z-[100] " + (!isTop ? "Navbar-sticky" : "")}
    >
      <div className="Navbar-wrapper">
        <div>
          <div className="Navbar-logoContainer">
            <Link to={`${prefix}/`}>
              <Img
                className="max-sm:!h-8"
                triggerPx={640}
                srcSmall={headerMiniIcon}
                srcLarge={headerIcon}
              />
            </Link>
          </div>
          <a
            type="button"
            className={
              isNavExpanded
                ? "Navbar-toggle  navbar-toggler"
                : "Navbar-toggle collapsed Navbar-toggle navbar-toggler"
            }
            onClick={() => {
              setIsNavExpanded(!isNavExpanded);
            }}
          >
            <span> </span>
            <span> </span>
            <span> </span>
          </a>
          <div
            className={
              // style={  (!isTop) ? ({top: '36px'}):({top: '48px'})  }
              isNavExpanded ? "Navbar-navWrapper expanded" : "Navbar-navWrapper"
            }
          >
            <nav>
              <ul>
                <li
                  onMouseOver={() => {
                    setIsSubNavExpanded(true);
                  }}
                  onMouseLeave={() => {
                    setIsSubNavExpanded(false);
                  }}
                  className="Navbar-menuContainer"
                >
                  {/*<Link to=''>*/}
                  Solutions <span aria-hidden="true">&#x25be;</span>
                  {/*</Link>*/}
                  <ul
                    className={
                      isSubNavExpanded
                        ? "Navbar-subExpanded Navbar-subMenu"
                        : "Navbar-subCollapsed Navbar-subMenu" + ""
                    }
                  >
                    <Link
                      to={`${prefix}/individual-coaching`}
                      className="inline-flex items-center justify-start"
                    >
                      <img
                        src={individualCoachingIcon}
                        className="mr-2"
                        alt="Individual coaching"
                      />
                      Individual coaching
                    </Link>

                    <Link
                      to={`${prefix}/group-workshop`}
                      className="inline-flex items-center justify-start"
                    >
                      <img
                        src={groupWorkshopsIcon}
                        className="mr-2"
                        alt="Group Workshops"
                      />
                      Group workshops
                    </Link>

                    <Link
                      to={`${prefix}/internal-coaching`}
                      className="inline-flex items-center justify-start"
                    >
                      <img
                        src={internalCoachingIcon}
                        className="mr-2"
                        alt="Internal coaching"
                      />
                      Internal coaching
                    </Link>

                    <Link
                      to={`${prefix}/executive-coaching`}
                      className="inline-flex items-center justify-start"
                    >
                      <img
                        src={executiveCoachingIcon}
                        className="mr-2"
                        alt="Executive coaching"
                      />
                      Executive coaching
                    </Link>

                    <Link
                      to={`${prefix}/ai-coaching`}
                      className="inline-flex items-center justify-start"
                    >
                      <img
                        src={aiSolutionsIcon}
                        className="mr-2"
                        alt="AI coaching"
                      />
                      AI coaching
                    </Link>
                    {/*<li>*/}
                    {/*    <Link to='/metaverse'>Metaverse</Link>*/}
                    {/*</li>*/}

                    {/*<li>*/}
                    {/*  <Link to={`${prefix}/climate-coaching`}>*/}
                    {/*    Climate Coaching*/}
                    {/*  </Link>*/}
                    {/*</li>*/}
                  </ul>
                </li>

                <li>
                  <Link to={`${prefix}/coaches`}>Coaches</Link>
                </li>
                {/*<li>*/}
                {/*  <Link to={`${prefix}/methodology`}>Methodology</Link>*/}
                {/*</li>*/}

                <li>
                  <Link to={`${prefix}/methodology`}>About Us</Link>
                </li>

                <li>
                  <Link to={`${prefix}/case-studies`}>Case Studies</Link>
                </li>
                {/*<li*/}
                {/*  onMouseOver={() => {*/}
                {/*    setIsSubCaseExpanded(true);*/}
                {/*  }}*/}
                {/*  onMouseLeave={() => {*/}
                {/*    setIsSubCaseExpanded(false);*/}
                {/*  }}*/}
                {/*  className=" Navbar-menuContainer"*/}
                {/*  style={{ opacity: "1" }}*/}
                {/*>*/}
                {/*  <Link to="/case-studies">*/}
                {/*    Case Studies <span aria-hidden="true">&#x25be;</span>*/}
                {/*  </Link>*/}
                {/*  <ul*/}
                {/*    className={*/}
                {/*      isSubCaseExpanded*/}
                {/*        ? "Navbar-subExpanded Navbar-subMenu"*/}
                {/*        : "Navbar-subCollapsed Navbar-subMenu"*/}
                {/*    }*/}
                {/*  >*/}
                {/*    <li>*/}
                {/*      <Link to="/meilleurs-agents">Meilleurs Agents</Link>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <Link to="/gorgias">Gorgias</Link>*/}
                {/*    </li>*/}
                {/*  </ul>*/}
                {/*</li>*/}
                <li>
                  <Link to={`${prefix}/thought-leadership`}>Blog</Link>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://dashboard.coachello.io"
                  >
                    <button className="Navbar-button">Login</button>
                  </a>
                </li>
                <li>
                  <Link to="/talk-to-expert">
                    <button className="Navbar-button-filled">
                      Talk to an expert &#10132;
                    </button>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        {/*<hr />*/}
      </div>
    </header>
  );
};

export default Navbar;
